define('frontend/authenticators/torii', ['exports', 'ember-simple-auth/authenticators/torii', 'frontend/config/environment'], function (exports, _torii, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _torii.default.extend({
    torii: Ember.inject.service(),
    ajax: Ember.inject.service(),

    authenticate: function authenticate() {
      var ajax = this.get('ajax');
      var tokenExchangeUri = _environment.default.torii.providers['gub-oauth2'].tokenExchangeUri;

      return this._super.apply(this, arguments).then(function (data) {
        return ajax.request(tokenExchangeUri, {
          type: 'POST',
          crossDomain: true,
          dataType: 'json',
          contentType: 'application/json',
          data: JSON.stringify({
            code: data.authorizationCode
          })
        }).then(function (response) {
          var token = response.access_token;
          return {
            authenticated: true,
            token: token,
            provider: data.provider
          };
        }).catch(function (error) {
          if ('payload' in error && 'errors' in error.payload) {
            throw error.payload.errors.detail;
          } else {
            throw error;
          }
        });
      });
    }
  });
});