define('frontend/torii-providers/gub', ['exports', 'torii/providers/oauth2-code', 'torii/configuration', 'frontend/config/environment'], function (exports, _oauth2Code, _configuration, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _oauth2Code.default.extend({
    name: 'gub-oauth2',
    baseUrl: _environment.default.APP.gubOAuth2AuthorizeUri,
    responseParams: ['code', 'state'],

    redirectUri: (0, _configuration.configurable)('redirectUri', function () {
      // A hack that allows redirectUri to be configurable
      // but default to the superclass
      return this._super();
    }),

    fetch: function fetch(data) {
      return data;
    }
  });
});