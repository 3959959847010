define('frontend/authenticators/librarycard', ['exports', 'ember-simple-auth/authenticators/base', 'frontend/config/environment'], function (exports, _base, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    store: Ember.inject.service(),

    authenticate: function authenticate(credentials) {
      var authCredentials = {};
      var that = this;
      authCredentials = {
        username: credentials.username,
        password: credentials.password
      };
      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.$.ajax({
          type: 'POST',
          url: _environment.default.APP.authenticationBaseURL,
          data: JSON.stringify(authCredentials),
          contentType: 'application/json'
        }).then(function (response) {
          var token = response.access_token;
          that.get('store').createRecord('token', { id: 1, token: token });
          resolve({
            authenticated: true,
            token: token
          });
        }, function (xhr) {
          reject(xhr.responseJSON);
        });
      });
    },
    invalidate: function invalidate() {
      // TODO: perhaps invalidate, or not implement, or reason to leave as is?
      return Ember.RSVP.resolve();
    }
  });
});