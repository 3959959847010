define("frontend/adapters/config", ["exports", "frontend/adapters/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    pathForType: function pathForType(type) {
      if (type === "config") {
        return type;
      }
    }
  });
});