define("frontend/routes/error", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    setupController: function setupController(controller, error) {
      console.log("error", error);
    }
  });
});