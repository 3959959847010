define('frontend/serializers/biblio', ['exports', 'ember-data', 'active-model-adapter'], function (exports, _emberData, _activeModelAdapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _activeModelAdapter.ActiveModelSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {

    attrs: {
      items: {
        deserialize: 'records'
      },
      subscriptiongroups: {
        deserialize: 'records'
      }

    }

  });
});