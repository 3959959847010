define('frontend/adapters/user', ['exports', 'frontend/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    urlForQueryRecord: function urlForQueryRecord(query, modelName) {
      var baseUrl = this.buildURL(modelName);
      return baseUrl + '/current';
    }
  });
});