define('frontend/components/reserve-note-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    inputLength: Ember.computed('value', function () {
      return this.get('value.length') || 0;
    }),

    isTextarea: Ember.computed('type', function () {
      if (this.get("type") === "textarea") {
        return true;
      }
      return false;
    }),

    isHidden: Ember.computed('type', function () {
      if (this.get("type") === "hidden") {
        return true;
      }
      return false;
    })
  });
});