define('frontend/routes/request-error', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        setupController: function setupController(controller, errors) {
            controller.set('errors', errors.errors);
            if (errors.errors.data) {
                controller.set('data', errors.errors.data);
            }
            this._super.apply(this, arguments);
        }
    });
});