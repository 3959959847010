define('frontend/controllers/request/login', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    i18n: Ember.inject.service(),
    session: Ember.inject.service(),
    request: Ember.inject.controller(),
    showForm: false,
    getLocale: Ember.computed('i18n.locale', 'i18n.locales', function () {
      return this.get('i18n.locale');
    }),

    actions: {
      login: function login() {
        var _this = this;

        this.set('oauth2ErrorMessage', false);
        this.set('usernamePasswordErrorMessage', false);

        var _getProperties = this.getProperties('username', 'password'),
            username = _getProperties.username,
            password = _getProperties.password;

        this.get('session').authenticate('authenticator:librarycard', { username: username, password: password }).catch(function (reason) {
          _this.set('usernamePasswordErrorMessage', _this.get('i18n').t('request.login.username-password-error'));
        });
      },
      loginOAuth2: function loginOAuth2() {
        var _this2 = this;

        this.set('oauth2ErrorMessage', false);
        this.set('usernamePasswordErrorMessage', false);
        this.set('showSpinner', true);
        return this.get('session').authenticate('authenticator:torii', 'gub').catch(function (reason) {
          _this2.set('oauth2ErrorMessage', _this2.get('i18n').t('request.login.oauth2-error'));
          _this2.set('showSpinner', false);
        });
      }
    },
    inputAutocomplete: Ember.computed(function () {
      return this.get('request.view') !== '46GUB_KOHA';
    }),
    showGULogin: Ember.computed(function () {
      return this.get('request.view') !== '46GUB_KOHA';
    }),
    libraryCardUrl: Ember.computed(function () {
      var lang = this.get('getLocale');
      return this.get('store').peekRecord('config', 1).get('registrationurl') + '?lang=' + lang;
    })
  });
});