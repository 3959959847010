define('frontend/models/sublocation', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    i18n: Ember.inject.service(),

    location: _emberData.default.belongsTo('location'),
    nameSv: _emberData.default.attr('string'),
    nameEn: _emberData.default.attr('string'),
    isOpenLoc: _emberData.default.attr('boolean'),
    isOpenPickupLoc: _emberData.default.attr('boolean'),
    isPagingLoc: _emberData.default.attr('boolean'),

    name: Ember.computed('i18n.locale', function () {
      switch (this.get('i18n.locale')) {
        case 'en':
          return this.get("nameEn");
        default:
          return this.get("nameSv");
      }
    })

  });
});