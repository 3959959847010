define('frontend/router', ['exports', 'frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('request', { path: ':id' }, function () {
      this.route('login', { path: 'login' });
      this.route('order', function () {
        this.route('items');
        this.route('details');
        this.route('summary');
        this.route('confirmation');
      });
    });

    this.route('request-error');
  });

  exports.default = Router;
});