define('frontend/models/user', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({

    username: _emberData.default.attr(),
    firstName: _emberData.default.attr(),
    lastName: _emberData.default.attr(),
    fullName: Ember.computed('firstName', 'lastName', function () {
      return this.get('firstName') + ' ' + this.get('lastName');
    }),
    finesAmount: _emberData.default.attr('number'),
    expirationDate: _emberData.default.attr('date'),

    denied: _emberData.default.attr('boolean'),
    warning: _emberData.default.attr('boolean'),

    deniedReasons: _emberData.default.attr(),
    warningReasons: _emberData.default.attr(),

    userCategory: _emberData.default.attr('string'),
    pickupCode: _emberData.default.attr('string')
  });
});