define("frontend/routes/request", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),

    beforeModel: function beforeModel(transition) {
      var biblioId = transition.params.request.id;

      if (biblioId === "error") {
        return new Ember.RSVP.Promise(function (resolve, reject) {
          reject({ errors: { errors: [{ "code": 'NO_ID', "detail": "loreum" }] } });
        });
      }
      if (!this.get('session.isAuthenticated')) {
        this.replaceWith('request.login');
      } else {
        this.replaceWith('request.order.items');
      }
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        id: Ember.RSVP.resolve(params.id),
        biblio: this.store.find('biblio', params.id)
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
    }
  });
});