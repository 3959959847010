define('frontend/routes/request/login', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    model: function model(params, transition) {
      var biblioId = transition.params.request.id;
      this.get('store').createRecord('tmp_biblio', { id: 1, biblio: biblioId });
      //??
    },
    setupController: function setupController(controller) {
      controller.set('showForm', true);
      controller.set('showSpinner', false);
    }
  });
});