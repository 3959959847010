define('frontend/routes/request/order', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),

    queryParams: {
      location: {
        replace: true
      },
      type: {
        replace: true
      }
    },

    model: function model() {
      var _this = this;

      var id = this.modelFor('request').id;
      return Ember.RSVP.hash({
        biblio: this.store.findRecord('biblio', id, { adapterOptions: { items_on_subscriptions: "true" } }),
        user: this.store.queryRecord('user', { biblio: id })
      }).then(function (result) {
        return Ember.RSVP.hash({
          locations: _this.get('store').findAll('location'),
          loantypes: _this.get('store').findAll('loanType'),
          biblio: Ember.RSVP.resolve(result.biblio),
          user: Ember.RSVP.resolve(result.user),
          reserve: _this.get('store').createRecord('reserve', {
            biblio: result.biblio,
            user: result.user
          })
        });
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
    },


    actions: {
      goBack: function goBack() {
        window.history.back();
      },
      submitOrder: function submitOrder() {
        var _this2 = this;

        this.controllerFor('request.order.summary').set('btnSubmitOrderDisabled', true);
        this.controller.get('model.reserve').save().then(function () {
          _this2.transitionTo('request.order.confirmation');
        }, function (error) {
          _this2.get('controller').set('errors', error.errors);
          _this2.transitionTo('request.order.confirmation');
        });
      }
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        var reserve = controller.get('model.reserve');
        if (reserve.get('isNew')) {
          reserve.destroyRecord();
        }
      }
    }
  });
});