define('frontend/controllers/request-error', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Ember.Controller.extend({
    i18n: inject.service(),

    reason: computed('errors', function () {
      var dictionary = this.get('i18n');
      // the node 'request-errors' in the locale files contains nodes corresponding to the error code.
      var errors = this.get('errors').errors;
      var header = dictionary.t('request-errors.header');
      var res = '<h3>' + header + '</h3>';

      if (errors && errors.length > 0) {
        errors.map(function (obj) {
          var eventLabel = '';
          if (obj.code) {
            var msg = dictionary.t('request-errors.' + obj.code + '.message');
            res += '<p>' + msg + '</p>';
            eventLabel = eventLabel + ' ' + obj.code;
          }
          if (dataLayer) {
            dataLayer.push({ 'event': 'GAEvent', 'eventCategory': 'Errors', 'eventAction': 'Request error', 'eventLabel': eventLabel.trim() });
          }
        });
      } else {
        res += dictionary.t('request-errors.UNKNOWN_ERROR.message');
        if (dataLayer) {
          dataLayer.push({ 'event': 'GAEvent', 'eventCategory': 'Errors', 'eventAction': 'Request error', 'eventLabel': 'UNKNOWN_ERROR' });
        }
      }
      return res;
    })
  });
});