define('frontend/models/location', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    i18n: Ember.inject.service(),

    sublocations: _emberData.default.hasMany('sublocation'),
    nameSv: _emberData.default.attr('string'),
    nameEn: _emberData.default.attr('string'),
    categories: _emberData.default.attr(),

    name: Ember.computed('i18n.locale', function () {
      if (this.get('i18n.locale') === 'sv') {
        return this.get('nameSv');
      }
      return this.get('nameEn');
    }),

    isPickupLocation: Ember.computed('categories', function () {
      return this.get('categories').includes('PICKUP');
    }),

    pickupLocationClosed: Ember.computed('categories', function () {
      return this.get('categories').includes('CLOSED');
    }),

    pickupTemporaryClosed: Ember.computed('categories', function () {
      return this.get('categories').includes('NO_PICKUP');
    })

  });
});