define('frontend/models/reserve', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({

    user: _emberData.default.belongsTo('user', { async: false, inverse: null }),
    location: _emberData.default.belongsTo('location', { async: false, inverse: null }),
    loanType: _emberData.default.belongsTo('loan-type', { async: false, inverse: null }),
    biblio: _emberData.default.belongsTo('biblio', { async: false, inverse: null }),
    item: _emberData.default.belongsTo('item', { async: false, inverse: null }),
    subscription: _emberData.default.belongsTo('subscription', { async: false, inverse: null }),
    reserveNotes: _emberData.default.attr('string'),
    queuePosition: _emberData.default.attr('string'),
    isReservedClicked: _emberData.default.attr('boolean'),

    subscriptionNotes: _emberData.default.attr('string'),
    subscriptionLocation: _emberData.default.attr('string'),
    subscriptionSublocationId: _emberData.default.attr('string'),
    subscriptionSublocation: _emberData.default.attr('string'),
    subscriptionCallNumber: _emberData.default.attr('string')

  });
});