define('frontend/helpers/is-active-tab', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isActiveTab = isActiveTab;
  function isActiveTab(params /*, hash*/) {
    var tab = params[0];
    var activeTab = params[1];
    if (!activeTab && tab === 'tab1') {
      return 'active';
    }
    if (activeTab && activeTab === tab) {
      return 'active';
    }
    return '';
  }

  exports.default = Ember.Helper.helper(isActiveTab);
});