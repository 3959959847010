define('frontend/adapters/biblio', ['exports', 'frontend/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    findRecord: function findRecord(store, type, id, snapshot) {
      if (snapshot.adapterOptions) {
        var url = this.buildURL(type.modelName, id, snapshot, 'findRecord');
        var query = {
          items_on_subscriptions: Ember.get(snapshot.adapterOptions, 'items_on_subscriptions')
        };
        return this.ajax(url, 'GET', { data: query });
      } else {
        return this._super.apply(this, arguments);
      }
    }
  });
});