define('frontend/adapters/application', ['exports', 'active-model-adapter', 'frontend/config/environment'], function (exports, _activeModelAdapter, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _activeModelAdapter.default.extend({
    session: Ember.inject.service(),
    namespace: 'api',
    host: _environment.default.APP.serviceURL,

    headers: Ember.computed(function () {
      return {
        'Authorization': 'Token ' + this.get('session.data.authenticated.token')
      };
    }).volatile(),

    handleResponse: function handleResponse(status, header, payload) {
      if (404 === status) {
        return {
          status: "404",
          errors: payload.errors
        };
      }

      if (403 === status) {
        return {
          status: "403",
          errors: payload.errors
        };
      }
      return this._super.apply(this, arguments);
    }
  });
});