define('frontend/components/item-order-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isVisible: Ember.computed.or('canBeQueued', 'canBeOrdered', 'isAvailible'),

    actions: {
      onOrderClick: function onOrderClick(isReserve) {
        if (isReserve) {
          this.set("isReservedClick", true);
        } else {
          this.set("isReservedClick", false);
        }

        this.get('onClick')();
      }
    }
  });
});