define('frontend/models/item', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    i18n: Ember.inject.service(),

    biblio: _emberData.default.belongsTo('biblio'),
    sublocation: _emberData.default.belongsTo('sublocation'),
    canBeOrdered: _emberData.default.attr('boolean'),
    canBeQueued: _emberData.default.attr('boolean'),
    itemType: _emberData.default.attr('string'),
    itemCallNumber: _emberData.default.attr('string'),
    copyNumber: _emberData.default.attr('string'),
    barcode: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    statusLimitation: _emberData.default.attr('string'),
    dueDate: _emberData.default.attr('string'),
    notForLoan: _emberData.default.attr('string'),
    isReserved: _emberData.default.attr('boolean'),
    isAvailible: _emberData.default.attr('boolean'),

    sublocationText: Ember.computed('sublocation.isOpenLoc', 'sublocation.name', 'itemCallNumber', function () {
      var dictionary = this.get('i18n');
      if (this.get('sublocation.isOpenLoc')) {
        if (this.get('itemCallNumber')) {
          return this.get('sublocation.name') + ', ' + this.get('itemCallNumber');
        } else if (this.get('biblio.biblioCallNumber')) {
          return this.get('sublocation.name') + ', ' + this.get('biblio.biblioCallNumber');
        } else {
          return this.get('sublocation.name');
        }
      } else {
        return dictionary.t('components.item-table.must_be_ordered');
      }
    }),

    statusLimitationText: Ember.computed('statusLimitation', function () {
      var dictionary = this.get('i18n');
      if (this.get('statusLimitation') === 'NOT_FOR_HOME_LOAN') {
        return dictionary.t('components.item-table.not_for_home_loan');
      } else if (this.get('statusLimitation') === 'READING_ROOM_ONLY') {
        return dictionary.t('components.item-table.reading_room_only');
      } else if (this.get('statusLimitation') === 'LOAN_IN_HOUSE_ONLY') {
        return dictionary.t('components.item-table.loan_in_house_only');
      } else {
        return '';
      }
    }),

    statusText: Ember.computed('status', 'dueDate', function () {
      var dictionary = this.get('i18n');

      if (this.get('status') === 'LOANED') {
        return dictionary.t('components.item-table.loaned') + ' ' + this.get('dueDate').slice(0, -8); // + moment(this.get('dueDate')).format("YYYY-MM-DD");
      } else if (this.get('status') === 'RESERVED') {
        return dictionary.t('components.item-table.reserved');
      } else if (this.get('status') === 'WAITING') {
        return dictionary.t('components.item-table.waiting');
      } else if (this.get('status') === 'IN_TRANSIT') {
        return dictionary.t('components.item-table.in_transit');
      } else if (this.get('status') === 'DELAYED') {
        return dictionary.t('components.item-table.delayed');
      } else if (this.get('status') === 'DURING_ACQUISITION') {
        return dictionary.t('components.item-table.under_acquisition');
      } else if (this.get('status') === 'NOT_IN_PLACE') {
        return dictionary.t('components.item-table.not_in_place');
      } else if (this.get('status') === 'AVAILABLE') {
        return dictionary.t('components.item-table.available');
      } else {
        return dictionary.t('components.item-table.unknown');
      }
    }),

    actions: {
      setItemToOrder: function setItemToOrder(item) {
        this.get('setItemToOrder')(item);
      }
    }
  });
});